let sanitizedPackageUsers: string;
let sanitizedLobbyPackageUsers: string;
let sanitizedCommissions: string;

export const LogRocketActionSanitizer = {
  actionSanitizer: function (action: any) {
    //Actions not listed below are sanitized/return null
    //because they are too large for LogRocket to record
    if (
      action.type.includes('[Package User]') ||
      action.type.includes('[MsalAuth]') ||
      action.type.includes('[Device Group]') ||
      action.type.includes('[SignalR]') ||
      action.type === '[Lobby] Fetch Lobby Details'
    ) {
      return action;
    }
    return null;
  },
};

export const LogRocketStateSanitizer = {
  stateSanitizer: function (state: any) {
    const IdTokenClaims = {
      email: 'sanitized',
      family_name: 'sanitized',
      given_name: 'sanitized',
    };

    //Maps packageUser to find and sanitize instances of all
    if (state.packageUsers.packageUsers) {
      sanitizedPackageUsers = state.packageUsers.packageUsers.map(
        (packageUser) => {
          return {
            ...packageUser,
            ...SanitizedPackageUsers,
          };
        }
      );
    }

    if (state.lobby.packageUsers) {
      //Maps lobbyPackageUser to find and sanitize intances of all
      sanitizedLobbyPackageUsers = state.lobby.packageUsers.map(
        (lobbyPackageUser) => {
          return {
            ...lobbyPackageUser,
            ...SanitizedLobbyPackageUsers,
          };
        }
      );
    }

    if (state.commissions.commissions) {
      //Maps comissions to find and sanitize instances of all
      sanitizedCommissions = state.commissions.commissions.map(
        (commissions) => {
          return {
            ...commissions,
            ...SanitizedCommissions,
          };
        }
      );
    }

    return {
      ...state,
      commissions: {
        ...state.commissions,
        commissions: sanitizedCommissions,
        selectedCommission: {
          ...state.commissions.selectedCommission,
          ...SelectedCommission,
        },
      },
      lobby: {
        ...state.lobby,
        packageUsers: sanitizedLobbyPackageUsers,
      },
      msalAuth: {
        ...state.msalAuth,
        user: {
          ...state.msalAuth.user,
          idTokenClaims: {
            ...state.msalAuth.user.idTokenClaims,
            ...IdTokenClaims,
          },
        },
      },
      packageUsers: {
        ...state.packageUsers,
        activePackageUser: {
          ...state.packageUsers.activePackageUser,
          ...ActivePackageUser,
        },
        packageUsers: sanitizedPackageUsers,
      },
      packages: {
        ...state.packages,
        ...Packages,
        package: {
          ...state.packages.package,
          ...Package,
        },
      },
      participantVerification: {
        ...state.participantVerification,
        ...SanitizedParticipantVerification,
      },
      wizard: {
        ...state.wizard,
        activeWizardUser: {
          ...state.wizard.activeWizardUser,
          ...ActiveWizardUser,
        },
      },
    };
  },
};

export const SelectedCommission = {
  certificate: 'sanitized',
  expiresOn: 'sanitized',
  id: 'sanitized',
  name: 'sanitized',
  number: 'sanitized',
  stamp: 'sanitized',
};

export const ActivePackageUser = {
  firstName: 'sanitized',
  lastName: 'sanitized',
  packageId: 'sanitized',
  packageUserId: 'sanitized',
  phoneNumber: 'sanitized',
};

export const Package = {
  city: 'sanitized',
  clientLoanNumber: 'sanitized',
  packageId: 'sanitized',
  signingStateCode: 'sanitized',
  stateCode: 'sanitized',
  streetAddress1: 'sanitized',
  streetAddress2: 'sanitized',
  zip: 'sanitized',
};

export const Packages = {
  activePackageId: 'sanitized',
  activePackageState: 'sanitized',
};

export const ActiveWizardUser = {
  firstName: 'sanitized',
  lastName: 'sanitized',
  packageId: 'sanitized',
  packageUserId: 'sanitized',
  signatureName: 'sanitized'
};

export const SanitizedPackageUsers = {
  firstName: 'sanitized',
  lastName: 'sanitized',
  packageId: 'sanitized',
  packageUserId: 'sanitized',
  phoneNumber: 'sanitized',
  signatureName: 'sanitized',
};

export const SanitizedLobbyPackageUsers = {
  firstName: 'sanitized',
  lastName: 'sanitized',
  packageId: 'sanitized',
  packageUserId: 'sanitized',
  signatureName: 'sanitized',
};

export const SanitizedCommissions = {
  certificate: 'sanitized',
  expiresOn: 'sanitized',
  id: 'sanitized',
  name: 'sanitized',
  number: 'sanitized',
  stamp: 'sanitized',
};

export const SanitizedParticipantVerification = {
  idVerifications: 'sanitized',
};
