<div class="video-problems-modal-container">
  <div class="video-problems-modal-container__header">
    <div class="sub-header">There's a problem with your device</div>
  </div>
  <div class="video-problems-modal-container__body">
    <p>
      If you have another camera or microphone attached to this computer, you may try switching to
      it using <a (click)="openDeviceSettingsModal()" class="link">A/V settings</a> below.
    </p>
    <p>
      Once you make your changes, you may need to <span (click)="refresh()" class="link">refresh the page</span>.
    </p>
    <p>
      If this continues to be an issue you may have to <span (click)="this.cancelPackageOnUserRequest()" class="link">cancel the session</span>.
    </p>
    <p>
      Need help? Call us:
        <a href="tel:{{ (client$ | async)?.supportPhoneNumber | phone }}">
          {{
          (client$ | async)?.supportPhoneNumber | phone
          }}
        </a>
    </p>
  </div>
  <div class="video-problems-modal-container__footer">
    <button type="button" class="btn btn--device-settings" (click)="openDeviceSettingsModal()">
      Go to A/V Settings
    </button>
  </div>
</div>
