import { createAction, props } from '@ngrx/store';

export const AudioVideoCheckStarted = createAction('[Audio Video Check] Audio Video Check Started');

export const SaveSessionCapableSuccessful = createAction(
  '[Audio Video Check] Audit Log save successful',
  props<{ packageGuid: string }>()
);

export const SaveSessionCapableFailed = createAction('[Audio Video Check] Audit Log save failed');

export const SaveAuditSessionCapable = createAction('[Audio Video Check] Save Session Capable');

export const GetUserMediaAudio = createAction('[Audio Check] GetUserMediaAudio');
export const GetUserMediaVideo = createAction('[Video Check] GetUserMediaVideo');
export const GetUserMediaAudioSuccess = createAction('[Audio Check] Get User Media Audio Success');
export const GetUserMediaVideoSuccess = createAction('[Video Check] Get User Media Video Success');
export const GetUserMediaAudioFailure = createAction(
'[Audio Check] Get User Media Audio Failure',
  props<{ error: Error }>()
);
export const GetUserMediaVideoFailure = createAction(
  '[Video Check] Get User Media Video Failure',
    props<{ error: Error }>()
  );

export const EnumerateDevicesSuccess = createAction(
  '[Audio Video Check] Enumerate Devices Success',
  props<{
    devices: MediaDeviceInfo[];
  }>()
);

export const SetMediaStream = createAction(
  '[Audio Video Check] Set MediaStream',
  props<{ mediaStream: MediaStream }>()
);

export const SelectMicrophone = createAction(
  '[Audio Select] Select Microphone',
  props<{ microphone: MediaDeviceInfo }>()
);

export const ClearMicrophone = createAction('[Audio Select] Clear Microphone');
export const ClearSpeaker = createAction('[Audio Select] Clear Speaker');
export const ClearVideo = createAction('[Audio Select] Clear Video');

export const SelectSpeaker = createAction(
  '[Audio Select] Select Speaker',
  props<{ speaker: MediaDeviceInfo }>()
);

export const SelectVideo = createAction(
  '[Audio Video Check] Select Video',
  props<{ video: MediaDeviceInfo }>()
);
