import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DashboardUrl } from 'src/app/features/package-users/models/dashboard-url.model';
import { PackageUserCheckInStatus } from 'src/app/features/package-users/models/package-user-checkin-status.model';

import {
  CheckInStatus,
  CompletePackageUserPreSignSession,
  CreatePackageUserPreSignSession,
  ExitPackageUserPreSignSession,
  PackageUser,
} from '../models';

@Injectable()
export class PackageUsersService {
  constructor(private readonly httpClient: HttpClient) {}

  getPackageUser(packageUserGuid: string) {
    return this.httpClient.get<PackageUser>(`packageUsers/${packageUserGuid}`);
  }

  createPackageUserPreSignSession(createPreSignSession: CreatePackageUserPreSignSession) {
    return this.httpClient.post('packageUsers/presignSessionStarted', createPreSignSession);
  }

  exitPackageUserPreSignSession(exitPreSignSession: ExitPackageUserPreSignSession) {
    return this.httpClient.post('packageUsers/presignSessionExited', exitPreSignSession);
  }

  completePackageUserPreSignSession(completePreSignSession: CompletePackageUserPreSignSession) {
    return this.httpClient.post('packageUsers/presignSessionComplete', completePreSignSession);
  }

  getPackageUsers(packageGuid: string) {
    return this.httpClient.get<PackageUser[]>(`packages/${packageGuid}/packageusers`);
  }

  getPackageUserCheckInStatus(packageUserGuid: string) {
    return this.httpClient.get<{ packageUserGuid: string; checkInStatus: CheckInStatus }>(
      `tasks/${packageUserGuid}/checkInStatus`
    );
  }

  getPackageCheckinStatuses(packageGuid: string) {
    return this.httpClient.get<PackageUserCheckInStatus[]>(`packageUsers/package/${packageGuid}/checkInStatuses`);
  }

  getActivePackageUserDashboardUrl() {
    return this.httpClient.get<DashboardUrl>(`consumerDashboard/packageUser/url`).pipe(
      catchError((res) => {
        return throwError(res);
      })
    );
  }
}
