import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { EulaComponent } from 'src/app/features/consents/containers';
import { DeviceCodeResolver } from 'src/app/features/device-group';
import { FeatureManagementResolver } from 'src/app/features/feature-management/resolvers/feature-management.resolver';
import {
  FeedbackComponent,
  FeedbackGuard,
  FeedbackUrlMatcher,
  InvalidURLComponent,
} from 'src/app/features/feedback';
import { FeedbackLayoutComponent, PreSessionLayoutComponent } from 'src/app/features/layout';
import {
  MsalAuthGuard,
  MsalFlowsGuard,
  RedirectInformationComponent,
} from 'src/app/features/msal-auth';
import { environment } from 'src/environments/environment';

import { ClientLoadedResolver } from './features/client';
import { CanActivatePresignGuard } from './features/pre-sign/guards/can-activate-presign.guard';
import { FlowSelectionGuard, PortalGuard, RejoinGuard, RouteSelectionGuard } from './guards';
import { PackageGuidGuard } from './guards/package-guid.guard';
import { PortalDeviceValidatorGuard } from './guards/portal-device-validator.guard';

const routes: Routes = [
  {
    path: '',
    component: RedirectInformationComponent,
    canActivate: [FlowSelectionGuard],
  },
  {
    path: 'signing-landing',
    component: RedirectInformationComponent,
    canActivate: [RouteSelectionGuard],
  },
  {
    path: 'invite',
    component: RedirectInformationComponent,
    canActivate: [MsalFlowsGuard],
  },
  {
    path: 'pinlogin',
    component: RedirectInformationComponent,
    canActivate: [MsalFlowsGuard],
  },
  {
    path: 'sa-login',
    component: RedirectInformationComponent,
    canActivate: [MsalFlowsGuard],
  },
  {
    path: 'nonsa-login',
    component: RedirectInformationComponent,
    canActivate: [MsalFlowsGuard, RejoinGuard],
  },
  {
    path: 'nonsa-account-login',
    component: RedirectInformationComponent,
    canActivate: [MsalFlowsGuard],
  },
  {
    matcher: FeedbackUrlMatcher,
    component: FeedbackLayoutComponent,
    children: [{ path: '', component: FeedbackComponent }],
    canActivate: [FeedbackGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      clientLoaded: ClientLoadedResolver,
    },
  },
  {
    path: 'consumer-portal',
    loadChildren: () =>
      import('./features/consumer-portal/consumer-portal.module').then(
        (m) => m.ConsumerPortalModule
      ),
    canLoad: [PortalGuard, MsalAuthGuard, PackageGuidGuard, PortalDeviceValidatorGuard],
    resolve: {
      deviceCode: DeviceCodeResolver,
      clientLoaded: ClientLoadedResolver,
      featureManagement: FeatureManagementResolver,
    },
  },
  {
    path: 'pre-sign',
    loadChildren: () => import('./features/pre-sign/pre-sign.module').then((m) => m.PreSignModule),
    canLoad: [PortalGuard, MsalAuthGuard, PortalDeviceValidatorGuard],
    canActivate: [CanActivatePresignGuard],
    resolve: {
      deviceCode: DeviceCodeResolver,
      clientLoaded: ClientLoadedResolver,
      featureManagement: FeatureManagementResolver,
    },
  },
  {
    path: 'hybrid',
    loadChildren: () => import('./features/hybrid/hybrid.module').then((m) => m.HybridModule),
    canLoad: [MsalAuthGuard],
    resolve: {
      deviceCode: DeviceCodeResolver,
      clientLoaded: ClientLoadedResolver,
      featureManagement: FeatureManagementResolver,
    },
  },
  {
    path: 'ipen',
    loadChildren: () => import('./features/ipen/ipen.module').then((m) => m.IpenModule),
    canLoad: [MsalAuthGuard],
    resolve: {
      deviceCode: DeviceCodeResolver,
      clientLoaded: ClientLoadedResolver,
      featureManagement: FeatureManagementResolver,
    },
  },
  {
    path: 'ron',
    loadChildren: () => import('./features/ron/ron.module').then((m) => m.RonModule),
    canLoad: [MsalAuthGuard],
    canActivate: [RejoinGuard],
    resolve: {
      deviceCode: DeviceCodeResolver,
      clientLoaded: ClientLoadedResolver,
      featureManagement: FeatureManagementResolver,
    },
  },
  {
    path: 'kron',
    loadChildren: () => import('./features/kron/kron.module').then((m) => m.KronModule),
    canLoad: [MsalAuthGuard],
    resolve: {
      deviceCode: DeviceCodeResolver,
      clientLoaded: ClientLoadedResolver,
      featureManagement: FeatureManagementResolver,
    },
  },
  {
    path: 'eula',
    component: PreSessionLayoutComponent,
    children: [{ path: '', component: EulaComponent }],
    canLoad: [MsalAuthGuard],
    resolve: {
      clientLoaded: ClientLoadedResolver,
    },
  },
  {
    path: '**',
    component: InvalidURLComponent,
  },
];

if (!environment.production) {
  routes.push({
    path: 'develop',
    loadChildren: () =>
      import('./features/_development/development.module').then((m) => m.DevelopmentModule),
  });
}

let extraOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  useHash: true,
};

// with hash routing strategy, if msal login uses an iframe for authentication,
// we need to disable initialNavigation to prevent a race condition
// between angular's routing and msal reading
// the state from the #state hash after a login.
const isInIframe = window !== window.parent && !window.opener;

if (isInIframe) {
  extraOptions = {
    ...extraOptions,
    initialNavigation: 'disabled',
  };
}

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
