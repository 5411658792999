import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalsActions } from 'src/app/features/modals';
import { ModalsSelectors } from 'src/app/features/modals/store';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';
import { LoadingTimeoutComponent } from '../features/shared/components';

import { RootStoreState } from '../store';

export interface LoadingInterceptorTimeoutData {
  apiBaseUrl: string;
  guidExpression: string;
  notificationId: string;
  requests: HttpRequest<unknown>[];
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  public snackBarRef: MatSnackBarRef<LoadingTimeoutComponent>;
  public loadingTimeout: ReturnType<typeof setTimeout> = undefined;

  private readonly loadingTimeoutInSeconds = 10;
  private readonly requests: HttpRequest<any>[] = [];

  private globalSpinner = false;
  private isLoading = false;
  private readonly delayInSeconds = 0;

  private readonly guidExpression =
    '([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})';
  private readonly urlExemptionList = [
    `consents/viewed`,
    `docs/presign/packageUsers/${this.guidExpression}`,
    `docs/signing/active`,
    `docs/signing/packageUsers/${this.guidExpression}`,
    'endorsements/erased',
    `endorsements/initials/preview/packageusers/${this.guidExpression}`,
    `endorsements/packageusers/${this.guidExpression}`,
    `endorsements/signatures/preview/packageusers/${this.guidExpression}`,
    'endorsements/signed',
    `endorsements/system`,
    `endorsements/systemEndorsementsApplied/packages/${this.guidExpression}`,
    `idVerification/status/packageUserGuid/${this.guidExpression}`,
    `packages/${this.guidExpression}/status`,
    `packageUsers/${this.guidExpression}/pinVerification`,
    `participantVerification/areAllParticipantVerificationsComplete/packageUsers/${this.guidExpression}`,
    `signalr/groups/subscribe`,
    `tasks/${this.guidExpression}/checkInStatus`, // TODO: Analysis on signal r issues in the signing room
    `waitingRoom/packages/${this.guidExpression}/participants`,
    `packageUsers/package/${this.guidExpression}/checkInStatuses`
  ];

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly snackBar: MatSnackBar
  ) {
    this.store
      .select(ModalsSelectors.globalSpinner)
      .pipe(tap((globalSpinner) => (this.globalSpinner = globalSpinner)))
      .subscribe();

    this.handleLoadingTimeout = this.handleLoadingTimeout.bind(this);
  }

  private isShowLoader(incomingUrl: string): boolean {
    const isInExemptionList = this.urlExemptionList.some(
      (url) =>
        incomingUrl.match(new RegExp(`${environment.apiBaseUrl}${url}`, 'ig')) ||
        incomingUrl.match(new RegExp(`${url}`, 'ig'))
    );
    return !isInExemptionList && this.globalSpinner;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isShowLoader(req.url)) {
      return next.handle(req);
    }

    this.requests.push(req);

    if (!this.isLoading) {
      setTimeout(() => this.showLoader(), this.delayInSeconds * 1000);
    }

    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.hideLoader();
  }

  handleLoadingTimeout(
    apiBaseUrl: string,
    guidExpression: string,
    notificationId: string,
    requests: HttpRequest<unknown>[]
  ) {
    const data: LoadingInterceptorTimeoutData = {
      apiBaseUrl,
      guidExpression,
      notificationId,
      requests,
    }

    this.snackBarRef = this.snackBar.openFromComponent(LoadingTimeoutComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data
    });
  }

  showLoader() {
    if (this.requests.length > 0 && !this.isLoading) {
      this.isLoading = true;
      this.store.dispatch(ModalsActions.ShowLoadingSpinner());

      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }

      this.loadingTimeout = setTimeout(
        () =>
          this.handleLoadingTimeout(
            environment.apiBaseUrl,
            this.guidExpression,
            uuid(),
            this.requests
          ),
        this.loadingTimeoutInSeconds * 1000
      );
    }
  }

  hideLoader() {
    if (this.requests.length === 0 && this.isLoading) {
      clearTimeout(this.loadingTimeout);

      this.isLoading = false;
      this.store.dispatch(ModalsActions.HideLoadingSpinner());
      this.snackBar.dismiss();
    }
  }
}
