import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import { Client, defaultClientInfo } from 'src/app/features/client/models';
import { CancelSigningModalComponent } from 'src/app/features/layout';
import { AudioVideoModalSwitchComponent } from 'src/app/features/layout/components/audio-video-modal-switch/audio-video-modal-switch.component';
import { ModalsActions, ModalSize } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

import { VideoOptOutReasons } from '../../enums';

@Component({
  selector: 'app-device-in-use-modal',
  templateUrl: './device-in-use-modal.component.html',
  styleUrls: ['./device-in-use-modal.component.scss'],
})
export class DeviceInUseModalComponent implements OnInit {
  static identifier = 'DeviceInUseModalComponent';
  client$: Observable<Client>;
  @Input() isPublishingError: boolean;

  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.client$ = of(defaultClientInfo);
  }

  openDeviceSettings() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: AudioVideoModalSwitchComponent,
          componentData: {
            shouldRefreshAfterSettingApplication: true,
          },
          modalSize: ModalSize.large,
        },
      })
    );
  }

  cancel() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: CancelSigningModalComponent,
          componentData: {
            fixedCategoryTypeCode: this.isPublishingError
              ? VideoOptOutReasons.PublishingError
              : VideoOptOutReasons.AudioVideoDeviceNotDetected,
          },
        },
      })
    );
  }
}
