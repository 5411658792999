import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  ExceptionType,
  NotificationsActions,
  NotificationType,
} from 'src/app/features/notifications';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { BrowserClientSupportOnBrowserLogRequestModel } from 'src/app/features/packages/models/browser-client-support-log-request.model';
import { PackagesService } from 'src/app/features/packages/services';
import { FeedbackType } from 'src/app/features/shared/models';
import { VideoActions } from 'src/app/features/video/store/actions';
import { RootStoreState } from 'src/app/store';
import { environment } from 'src/environments/environment';

import { v4 as uuid } from 'uuid';

import { PackageScheduleStatus } from '../../models';
import { PackagesActions } from '../actions';
import { PackagesSelectors } from '../selectors';
import { ModalsActions } from 'src/app/features/modals';

@Injectable()
export class PackagesNavigationEffects {
  checkClientsSupportOnBrowserWithClientHints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.CheckDeviceSupportWithClientHints),
      concatLatestFrom(() => this.store.select(PackageUsersSelectors.getIsSigningAgent)),
      exhaustMap(([action, isSigningAgent]) => {
        return this.packagesService
          .getClientsSupportOnBrowserWithClientHints(
            action.payload.packageGuid,
            action.payload.packageUserGuid,
            action.payload.clientHints
          )
          .pipe(
            switchMap((browserClientSupportResult) => {
              const browserClientSupportClientHintsModel: BrowserClientSupportOnBrowserLogRequestModel =
                {
                  clientHints: action.payload.clientHints,
                  isBrowserSupported: browserClientSupportResult.isBrowserSupported,
                  isDeviceSupported: browserClientSupportResult.isDeviceSupported,
                  isOperatingSystemSupported: browserClientSupportResult.isOperatingSystemSupported,
                };

              this.store.dispatch(
                PackagesActions.LogDeviceSupportOnBrowser({
                  payload: {
                    browserClientSupportOnBrowserLogModel: browserClientSupportClientHintsModel,
                  },
                })
              );

              return this.handleCheckClientsSupportOnBrowser(
                action.payload.packageGuid,
                action.payload.packageUserGuid,
                isSigningAgent,
                browserClientSupportResult
              );
            }),
            catchError((error: HttpErrorResponse) => {
              return [
                NotificationsActions.AddNotification({
                  payload: {
                    exception: new Error(error.error),
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: "Failed to fetch client's support on browser",
                    exceptionType: ExceptionType.Other,
                  },
                }),
              ];
            })
          );
      })
    )
  );

  checkClientsSupportOnBrowserWithUserAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.CheckDeviceSupportWithUserAgent),
      concatLatestFrom(() => this.store.select(PackageUsersSelectors.getIsSigningAgent)),
      exhaustMap(([action, isSigningAgent]) => {
        return this.packagesService.getClientsSupportOnBrowserWithUserAgent().pipe(
          switchMap((browserClientSupportResult) => {
            const browserClientSupportClientHintsModel: BrowserClientSupportOnBrowserLogRequestModel =
              {
                isBrowserSupported: browserClientSupportResult.isBrowserSupported,
                isDeviceSupported: browserClientSupportResult.isDeviceSupported,
                isOperatingSystemSupported: browserClientSupportResult.isOperatingSystemSupported,
              };

            this.store.dispatch(
              PackagesActions.LogDeviceSupportOnBrowser({
                payload: {
                  browserClientSupportOnBrowserLogModel: browserClientSupportClientHintsModel,
                },
              })
            );

            return this.handleCheckClientsSupportOnBrowser(
              action.payload.packageGuid,
              action.payload.packageUserGuid,
              isSigningAgent,
              browserClientSupportResult
            );
          }),
          catchError((error: HttpErrorResponse) => {
            return [
              NotificationsActions.AddNotification({
                payload: {
                  exception: new Error(error.error),
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: "Failed to fetch client's support on browser",
                  exceptionType: ExceptionType.Other,
                },
              }),
            ];
          })
        );
      })
    )
  );

  logClientsSupportOnBrowser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PackagesActions.LogDeviceSupportOnBrowser),
      exhaustMap((action) => {
        return this.packagesService
          .postClientsSupportOnBrowserLog(action.payload.browserClientSupportOnBrowserLogModel)
          .pipe(
            switchMap(() => {
              return [];
            }),
            catchError((error: HttpErrorResponse) => {
              return [
                NotificationsActions.AddNotification({
                  payload: {
                    exception: new Error(error.error),
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: "Failed to log client's support on browser",
                    exceptionType: ExceptionType.Other,
                  },
                }),
              ];
            })
          );
      })
    )
  );

  invalidPackageScheduleStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectInvalidPackageScheduleStatus),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(
              this.store.pipe(select(PackagesSelectors.getPackageScheduleStatus)),
              this.store.pipe(select(PackagesSelectors.getProductRoute))
            )
          )
        ),
        tap(([action, packageScheduleStatus, productRoute]) => {
          let url: string = null;
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          switch (packageScheduleStatus) {
            case PackageScheduleStatus.EARLY: {
              url = `${productRoute}/${FeedbackType.EARLY}`;
              break;
            }
            case PackageScheduleStatus.PROCESSING: {
              url = `${productRoute}/${FeedbackType.PROCESSING}`;
              break;
            }
            case PackageScheduleStatus.ORDERNOTREADY: {
              url = `${productRoute}/${FeedbackType.ORDERNOTREADY}`;
              break;
            }
            case PackageScheduleStatus.EXPIRED: {
              url = `${productRoute}/${FeedbackType.EXPIRED}`;
              break;
            }
            case PackageScheduleStatus.CANCELLED: {
              url = `${productRoute}/${FeedbackType.CANCELLED}`;
              break;
            }
            case PackageScheduleStatus.COMPLETE: {
              url = `${productRoute}/${FeedbackType.COMPLETED}`;
              break;
            }
            case PackageScheduleStatus.CURRENT: {
              break;
            }
            default: {
              url = !!productRoute
                ? `${productRoute}/${FeedbackType.INVALIDURL}`
                : FeedbackType.INVALIDURL;
              break;
            }
          }

          if (!!url) {
            this.router.navigate([url], options);
          }
        })
      ),
    { dispatch: false }
  );

  unsupportedBrowser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectUnsupportedBrowser),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          const route = productRoute
            ? `${productRoute}/${FeedbackType.UNSUPPORTEDBROWSER}`
            : `${FeedbackType.UNSUPPORTEDBROWSER}`;
          this.router.navigate([route], options);
        })
      ),
    { dispatch: false }
  );

  unsupportedOperatingSystem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectUnsupportedOperatingSystem),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          const route = productRoute
            ? `${productRoute}/${FeedbackType.UNSUPPORTEDOPERATINGSYSTEM}`
            : `${FeedbackType.UNSUPPORTEDOPERATINGSYSTEM}`;
          this.router.navigate([route], options);
        })
      ),
    { dispatch: false }
  );

  unsupportedDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectUnsupportedDevice),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          const route = productRoute
            ? `${productRoute}/${FeedbackType.UNSUPPORTEDDEVICE}`
            : `${FeedbackType.UNSUPPORTEDDEVICE}`;
          this.router.navigate([route], options);
        })
      ),
    { dispatch: false }
  );

  smartphonesUnsupported$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectSmartphonesUnsupported),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          this.router.navigate([`${productRoute}/${FeedbackType.SMARTPHONESUNSUPPORTED}`], options);
        })
      ),
    { dispatch: false }
  );

  invalidUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectInvalidUrl),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          const url = !!productRoute
            ? `${productRoute}/${FeedbackType.INVALIDURL}`
            : FeedbackType.INVALIDURL;
          this.router.navigate([url], options);
        })
      ),
    { dispatch: false }
  );

  orderCancelled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectOrderCancelled),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          this.store.dispatch(VideoActions.StopVideo());
          //Need this in case the participants go back to one of the checkin pages
          //after the session is started and package gets cancelled due to any failure.
          let options = {
            queryParamsHandling: 'preserve',
            replaceUrl: true,
          } as NavigationExtras;
          if (action.payload?.queryParams) {
            options = {
              queryParams: action.payload?.queryParams,
              replaceUrl: true,
            } as NavigationExtras;
          }
          const route = productRoute
            ? `${productRoute}/${FeedbackType.CANCELLED}`
            : `${FeedbackType.CANCELLED}`;
          this.router.navigate([route], options);
        })
      ),
    { dispatch: false }
  );

  kbaFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectKbaFailure),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = {
            queryParamsHandling: 'preserve',
            replaceUrl: true,
            state: { data: action.payload },
          } as NavigationExtras;

          this.router.navigate([`${productRoute}/${FeedbackType.KBAFAIL}`], options);
        })
      ),
    { dispatch: false }
  );

  unAuthorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectUnAuthorized),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          const url = !!productRoute
            ? `${productRoute}/${FeedbackType.UNAUTHORIZED}`
            : FeedbackType.UNAUTHORIZED;

          this.store.dispatch(ModalsActions.ClearModalComponent());

          this.router.navigate([url], options);
        })
      ),
    { dispatch: false }
  );

  expiredLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectExpiredLink),
        tap(() => {
          // stop the router first, to prevent any additional navigation events
          // from being attempted during redirection to the external URL
          this.router.dispose();
          location.href = environment.expiredTokenUrl;
        })
      ),
    { dispatch: false }
  );

  invalidLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectInValidLink),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductRoute)))
          )
        ),
        tap(([action, productRoute]) => {
          const options = action.payload?.queryParams
            ? ({ queryParams: action.payload?.queryParams } as NavigationExtras)
            : ({ queryParamsHandling: 'preserve' } as NavigationExtras);

          const url = !!productRoute
            ? `${productRoute}/${FeedbackType.INVALIDLINK}`
            : FeedbackType.INVALIDLINK;
          this.router.navigate([url], options);
        })
      ),
    { dispatch: false }
  );

  genericErrorLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.RedirectGenericError),
        tap(() => {
          this.router.navigate([FeedbackType.GENERICERROR]);
        })
      ),
    { dispatch: false }
  );

  redirectToOptOutPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PackagesActions.OptOutSuccess, PackagesActions.HandleOptOut),
        concatLatestFrom(() => this.store.select(PackagesSelectors.getProductRoute)),
        tap(([_, productRoute]) => {
          const route = productRoute
            ? `${productRoute}/${FeedbackType.OPTOUT}`
            : `${FeedbackType.OPTOUT}`;

          this.router.navigate([route], { queryParamsHandling: 'preserve', replaceUrl: true });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly packagesService: PackagesService,
    private readonly store: Store<RootStoreState.State>
  ) {}

  handleCheckClientsSupportOnBrowser(
    packageGuid: string,
    packageUserGuid: string,
    isSigningAgent: boolean,
    browserClientSupportResult: any
  ) {
    const queryParams = isSigningAgent
      ? {
          package: packageGuid,
          user: packageUserGuid,
        }
      : {
          package: packageGuid,
        };

    const resultActions: Array<any> = [];

    if (!browserClientSupportResult.isDeviceSupported) {
      resultActions.push(PackagesActions.RedirectUnsupportedDevice({ queryParams }));
    } else if (!browserClientSupportResult.isOperatingSystemSupported) {
      resultActions.push(
        PackagesActions.RedirectUnsupportedOperatingSystem({
          queryParams,
        })
      );
    } else if (!browserClientSupportResult.isBrowserSupported) {
      resultActions.push(PackagesActions.RedirectUnsupportedBrowser({ queryParams }));
    }

    return resultActions;
  }
}
