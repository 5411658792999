import { CommonModule } from '@angular/common';
import { Component, inject, input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services/navigator-media-device-wrapper.service';
import { AudioSelectComponent } from 'src/app/features/av-check/components/audio-select/audio-select.component';
import { VideoSelectionComponent } from 'src/app/features/av-check/components/video-selection/video-selection.component';
import { SharedComponentService } from 'src/app/features/av-check/services/shared-component.service';

import { AudioVideoCheckActions } from 'src/app/features/av-check/store';
import { CheckInActions } from 'src/app/features/check-in';
import { PackagesModule } from 'src/app/features/packages';
import { SharedModule } from 'src/app/features/shared';

@Component({
  selector: 'app-audio-video-check-v2',
  standalone: true,
  imports: [
    AudioSelectComponent,
    CommonModule,
    PackagesModule,
    SharedModule,
    VideoSelectionComponent,
  ],
  templateUrl: './audio-video-check-v2.component.html',
  styleUrl: './audio-video-check-v2.component.scss',
})
export class AudioVideoCheckV2Component implements OnDestroy {
  private readonly store = inject(Store);
  private readonly navigatorMediaDeviceWrapper = inject(NavigatorMediaDeviceWrapperService);
  private readonly destroying$ = new Subject();

  sharedComponentService = inject(SharedComponentService);
  useDefaultActions = input(true);

  constructor() {
    this.store.dispatch(AudioVideoCheckActions.GetUserMediaAudio());
    this.store.dispatch(AudioVideoCheckActions.GetUserMediaVideo());

    this.navigatorMediaDeviceWrapper
      .onDeviceChange()
      .pipe(
        takeUntil(this.destroying$),
        tap(() => {
          this.store.dispatch(AudioVideoCheckActions.ClearVideo());
          this.store.dispatch(AudioVideoCheckActions.ClearMicrophone());
          this.store.dispatch(AudioVideoCheckActions.ClearSpeaker());

          this.store.dispatch(AudioVideoCheckActions.GetUserMediaAudio());
          this.store.dispatch(AudioVideoCheckActions.GetUserMediaVideo());
        })
      )
      .subscribe();
  }

  continue() {
    this.store.dispatch(AudioVideoCheckActions.SaveAuditSessionCapable());
    this.store.dispatch(CheckInActions.ContinueCheckInOrRejoin());
  }

  ngOnDestroy() {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
