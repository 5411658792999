import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import {
  INITIAL_ROUTE_PATH_SESSION_KEY,
  SECURE_TOKEN_STORAGE_KEY,
} from 'src/app/features/shared/constants';
import { RootStoreState } from 'src/app/store';

import { MsalFlow } from '../models';
import { MsalAuthService } from '../services';
import { MsalAuthActions } from '../store';

@Injectable({
  providedIn: 'root',
})
export class MsalFlowsGuard {
  constructor(
    private readonly msalAuthService: MsalAuthService,
    private readonly router: Router,
    private readonly store: Store<RootStoreState.State>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> {
    const idTokenHint = route.queryParams['id_token_hint'];

    const routeRoot = route.url[0];

    if (idTokenHint) {
      sessionStorage.setItem(SECURE_TOKEN_STORAGE_KEY, idTokenHint);
    }

    if (!routeRoot || !idTokenHint) {
      return of(this.router.parseUrl('/invalid-link'));
    }

    sessionStorage.removeItem(INITIAL_ROUTE_PATH_SESSION_KEY);

    const routePath = routeRoot.path.toLowerCase();

    return this.msalAuthService.handleRedirectObservable().pipe(
      concatMap(() => {
        for (const flow of [
          MsalFlow.Invite,
          MsalFlow.PinLogin,
          MsalFlow.NonSigningAgentLogin,
          MsalFlow.NonSigningAgentAccountLogin,
          MsalFlow.SigningAgentLogin,
        ]) {
          if (routePath.includes(flow)) {
            this.store.dispatch(
              MsalAuthActions.FlowLogin({
                payload: { msalFlow: flow, idTokenHint },
              })
            );
            return of(true);
          }
        }

        return of(this.router.parseUrl('/invalid-link'));
      })
    );
  }
}
