<img class="feedback-image" src="../../../assets/images/support-cog.svg" alt="" data-testid="opt-out-pic" />
<div class="message-container">
  <h1 class="message-container__header" data-testid="opt-out-header">
    Session canceled by one of the participants
  </h1>
  <p class="message-container__message" data-testid="opt-out-text">
    The signing session was cancelled by one of the members of your signing party. Unfortunately, it cannot proceed at
    this time.<br /><br />

    To reschedule your signing session and for more information please call support:
    <app-support-phone></app-support-phone>
  </p>
</div>