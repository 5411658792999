import { CancelSigningModalComponent } from 'src/app/features/layout/components/cancel-signing-modal/cancel-signing-modal.component';

export const INVALID_DISPATCH_MODAL_COMPONENT_IDENTIFIERS = [
  CancelSigningModalComponent.identifier,
];

export const INVALID_DEVICE_CHANGE_MODAL_ROUTE_SEGMENTS = [
  '/av-check',
  '/expired-commission',
  '/welcome',
];

export const isValidAudioVideoConnectionMonitorEvent = (eventId = null, invalidEventIds = []) =>
  invalidEventIds?.reduce((pre, curr) => (eventId?.includes(curr) ? false : pre), true) ?? true;
