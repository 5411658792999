import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import { DocumentsActions } from 'src/app/features/documents';
import { Feature } from 'src/app/features/feature-management/models';
import { ModalsActions } from 'src/app/features/modals';
import { NotificationsActions, NotificationType } from 'src/app/features/notifications';
import { AvDisruptionActionBlockingResponse, ExceptionType } from 'src/app/features/notifications/models';
import { PackageUserRole, PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { ProductType } from 'src/app/features/packages/models';
import { SignalRActions } from 'src/app/features/signal-r';
import { SigningRoomActions } from 'src/app/features/signing-room/store/actions';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';
import { v4 as uuid } from 'uuid';

import {
  ApplyYourSignatureModalComponent,
  AwaitingParticipantsModalComponent,
} from '../../components';
import { EndorsementType, UniqueEndorsementImage } from '../../models';
import { EndorsementsService } from '../../services';
import { EndorsementsActions } from '../actions';
import { scaffoldUniqueEndorsementImageModel } from '../helpers';
import { EndorsementsSelectors } from '../selectors';
import { FeatureManagementService } from 'src/app/features/feature-management/services';
import { ActionBlockingSnackbarComponent, AvDisruptionActionBlockingSnackbarComponent } from 'src/app/features/notifications/components';

const MAX_SYSTEM_FIELD_ATTEMPTS = 10;
const SYSTEM_FIELD_ATTEMPTS_BACKOFF = 5;

@Injectable()
export class EndorsementsEffects {
  // Notification Id constant to prevent multiple toast messages
  notificationId: string;

  configureListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignalRActions.SignalRRoomJoined),
        map(() => {
          this.endorsementsService.configureListeners();
        })
      ),
    { dispatch: false }
  );

  setEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.SetDocuments),
      switchMap(({ payload }) => {
        if (!payload?.documentEndorsementLocations) {
          return [];
        }

        const { documentEndorsementLocations } = payload;
        const uniqueEndorsementImages = Array<UniqueEndorsementImage>();

        documentEndorsementLocations.forEach((endorsementLocation, index, endorsementLocations) => {
          if (endorsementLocation.endorsementTypeCode === 'TEXTBOX') {
            if (endorsementLocation.endorsementValue) {
              endorsementLocations[index] = {
                ...endorsementLocation,
                // TODO: User Story 50434: Remove Old Endorsement Application Logic
                attempted: true,
              };
            }
            uniqueEndorsementImages.push(scaffoldUniqueEndorsementImageModel(endorsementLocation));
            return;
          }

          if (endorsementLocation.endorsementValue && !endorsementLocation.isRequired) {
            endorsementLocations[index] = {
              ...endorsementLocation,
              // TODO: User Story 50434: Remove Old Endorsement Application Logic
              attempted: true,
            };
          }
        });

        return [
          EndorsementsActions.SetEndorsementLocations({
            payload: documentEndorsementLocations,
          }),
          EndorsementsActions.CreateUniqueEndorsementImagePlaceholders({
            payload: uniqueEndorsementImages,
          }),
        ];
      })
    )
  );

  updatePackageUserEndorsementsAsSignableOnDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.UpdateSignableOnDeviceStatusByPackageUserGuid),
      concatLatestFrom((action) => [
        this.store.pipe(
          select(EndorsementsSelectors.selectEndorsementLocationsByPackageUserGuid, {
            packageUserGuid: action.payload.packageUserGuid,
          })
        ),
      ]),
      switchMap(([action, packageUserEndorsementLocations]) => {
        return [
          EndorsementsActions.SetUpdatedSignableOnDeviceStatus({
            payload: {
              endorsementLocations: packageUserEndorsementLocations,
              enable: action.payload.enable,
            },
          }),
        ];
      })
    )
  );

  refreshPackageUserEndorsementsSignableStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.RefreshEndorsementsSignableStatus),
      withLatestFrom(
        this.store.pipe(select(EndorsementsSelectors.selectAllUserEndorsementLocations))
      ),
      switchMap(([action, endorsementLocations]) => [
        EndorsementsActions.RefreshEndorsementsUpdateSignableOnDeviceStatusByPackageUserGuids({
          payload: {
            devicePackageUserGuids: action.payload.devicePackageUserGuids,
            endorsementLocations,
          },
        }),
      ])
    )
  );

  fetchEndorsementImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.FetchEndorsementImages),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)))
        )
      ),
      switchMap(([_, packageUserGuid]) =>
        this.endorsementsService.getEndorsementImages(packageUserGuid).pipe(
          map((payload) =>
            EndorsementsActions.SetEndorsementImages({
              payload: payload.packageEndorsements,
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Endorsement Images',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        )
      )
    )
  );

  fetchFreeTextEndorsementImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.FetchFreeTextEndorsementImages),
      switchMap(() =>
        this.endorsementsService.getUniqueEndorsementImages().pipe(
          map((payload) =>
            EndorsementsActions.SetUniqueEndorsementImages({
              payload: payload.packageEndorsementFreeTextImages,
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get Free Text Endorsement Images',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        )
      )
    )
  );

  fetchSystemEndorsementImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.FetchSystemEndorsementImages),
      filter(
        () =>
          this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NeaSystemFields) ===
          true
      ),
      switchMap(() =>
        this.endorsementsService.getSystemEndorsementImages().pipe(
          map((payload) =>
            EndorsementsActions.SetSystemEndorsementImages({
              payload: payload.packageSystemEndorsements,
            })
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to get System Endorsement Images',
                  exceptionType: ExceptionType.ReloadRetry,
                },
              })
            )
          )
        )
      )
    )
  );

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  setAttemptingEndorsement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.AttemptEndorsement),
      map(() =>
        EndorsementsActions.SetAttemptingEndorsement({
          payload: { isAttempting: true },
        })
      )
    )
  );

  setProcessingEndorsement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EndorsementsActions.ProcessSignEndorsement,
        EndorsementsActions.ProcessEraseSignedEndorsement
      ),
      map(() =>
        EndorsementsActions.SetProcessingEndorsement({
          payload: { isProcessing: true },
        })
      )
    )
  );

  showApplyingYourSignatureModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        // TODO: User Story 50434: Remove Old Endorsement Application Logic
        EndorsementsActions.AttemptEndorsement
        // EndorsementsActions.ProcessSignEndorsement,
        // EndorsementsActions.ProcessEraseSignedEndorsement
      ),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(PackagesSelectors.getProductType))))
      ),

      switchMap(([action, productType]) => {
        // TODO: User Story 50434: Remove Old Endorsement Application Logic
        if (
          action.type === '[Endorsements] Attempt Endorsement' &&
          action.payload.certificatePassword
        ) {
          return [];
        }

        if (productType === ProductType.RemoteSigning) {
          return [
            ModalsActions.SetStandaloneModalComponent({
              payload: {
                component: ApplyYourSignatureModalComponent,
              },
            }),
          ];
        } else {
          return [ModalsActions.ShowLoadingSpinner()];
        }
      })
    )
  );

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  attemptEndorsement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.AttemptEndorsement),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(DeviceGroupSelectors.getDeviceCode)),
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(PackagesSelectors.getProductType)),
            this.store.pipe(select(PackagesSelectors.isPreSign))
          )
        )
      ),
      exhaustMap(([action, deviceCode, userGuid, packageGuid, productType, isPresign]) =>
        this.endorsementsService.attemptEndorsement(action.payload, deviceCode, isPresign).pipe(
          switchMap((response: any) => {
            const defaultActions = [
              EndorsementsActions.EndorsementAttemptAccepted(action),
              EndorsementsActions.SetAttemptingEndorsement({
                payload: { isAttempting: false },
              }),
              ...this.getHideLoadingSpinner(productType, true),
            ];

            if (response?.endorsementId) {
              if (response.endorsementImage.length === 0) {
                return [
                  ...defaultActions,
                  EndorsementsActions.ClearUniqueEndorsementImage({
                    payload: {
                      documentEndorsementLocationId: action.payload.endorsementLocationId,
                    },
                  }),
                ];
              }

              return [
                ...defaultActions,
                EndorsementsActions.UpdateUniqueEndorsementImage({
                  payload: {
                    endorsementImageString: response.endorsementImage,
                    documentEndorsementLocationId: action.payload.endorsementLocationId,
                  },
                }),
                EndorsementsActions.SetEndorsementAttempted({
                  payload: {
                    endorsementLocationId: action.payload.endorsementLocationId,
                  },
                }),
              ];
            }

            if (action.payload.optional) {
              return defaultActions;
            }

            return [
              ...defaultActions,
              EndorsementsActions.SetEndorsementConfirmed({
                payload: {
                  endorsementLocationId: action.payload.endorsementLocationId,
                },
              }),
            ];
          }),
          catchError((error) => {
            const err = error;
            err.message = `Endorsement Attempt Request Failed user: ${userGuid} package: $ {packageGuid}`;
            this.applicationInsightsService.logException(err, SeverityLevel.Error, {
              packageUserGuid: userGuid,
              packageGuid: packageGuid,
              deviceCode: deviceCode,
            });

            // Prevent multiple of the same toast from being displayed
            if (this.notificationId) {
              this.store.dispatch(
                NotificationsActions.ClearNotification({
                  payload: { notificationId: this.notificationId },
                })
              );
            }

            this.notificationId = uuid();

            return of(
              ModalsActions.HideLoadingSpinner(),
              ModalsActions.ClearModalComponent(),
              EndorsementsActions.RollbackEndorsementAttempt({
                payload: {
                  endorsementLocationId: action.payload.endorsementLocationId,
                },
              }),
              NotificationsActions.AddNotification({
                payload: {
                  notificationType: NotificationType.Info,
                  id: this.notificationId,
                  text: 'Endorsement failed to apply. Please try again.',
                  exceptionType: ExceptionType.DisplayMessage,
                },
              }),

              EndorsementsActions.SetAttemptingEndorsement({
                payload: { isAttempting: false },
              })
            );
          })
        )
      )
    )
  );

  // this only gets called by the device that is currently "clicking to apply"
  signEndorsement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.ProcessSignEndorsement),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(PackagesSelectors.getProductType))
          )
        )
      ),
      exhaustMap(([action, userGuid, packageGuid, productType]) =>
        this.endorsementsService.signEndorsement(action.payload).pipe(
          switchMap((response: any) => {
            const commonSignEndorsementActions = [
              EndorsementsActions.SignEndorsementCallCompleted(action),
              EndorsementsActions.SetProcessingEndorsement({
                payload: { isProcessing: false },
              }),
              EndorsementsActions.SetEndorsementSigned({
                payload: {
                  endorsementLocationId: action.payload.endorsementLocationId,
                },
              }),
              ...this.getHideLoadingSpinner(productType, true),
            ];

            return [...commonSignEndorsementActions];
          }),
          catchError((error) => {
            const err = error;
            err.message =
              'Sign Endorsement Request Failed' + ' user:' + userGuid + ' package:' + packageGuid;
            this.applicationInsightsService.logException(err, SeverityLevel.Error);

            // Prevent multiple of the same toast from being displayed
            if (this.notificationId) {
              this.store.dispatch(
                NotificationsActions.ClearNotification({
                  payload: { notificationId: this.notificationId },
                })
              );
            }

            this.notificationId = uuid();

            const actions = [
              ModalsActions.HideLoadingSpinner(),
              ModalsActions.ClearModalComponent(),
              EndorsementsActions.RollbackEndorsement({
                payload: {
                  endorsementLocationId: action.payload.endorsementLocationId,
                },
              }),
              EndorsementsActions.SetProcessingEndorsement({
                payload: { isProcessing: false },
              }),
            ];

            if (error.status === HttpStatusCode.Locked) {
              return [
                ...actions,
                NotificationsActions.AddSnackBarNotification({
                  payload: {
                    component: error.error instanceof AvDisruptionActionBlockingResponse ?
                      AvDisruptionActionBlockingSnackbarComponent : ActionBlockingSnackbarComponent,
                    title: 'Endorsement not applied',
                    body: error.error.text,
                    data: error.error,
                    panelClass: 'mdc-snackbar-error',
                    duration: 12000,
                  },
                }),
              ];
            } else {
              return [
                ...actions,
                NotificationsActions.AddNotification({
                  payload: {
                    notificationType: NotificationType.Info,
                    id: this.notificationId,
                    text: 'Endorsement failed to sign. Please try again.',
                    exceptionType: ExceptionType.DisplayMessage,
                  },
                }),
              ];
            }
          })
        )
      )
    )
  );

  eraseSignedEndorsement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.ProcessEraseSignedEndorsement),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(PackageUsersSelectors.getActivePackageUserGuid)),
            this.store.pipe(select(PackagesSelectors.getActivePackageGuid)),
            this.store.pipe(select(PackagesSelectors.getProductType))
          )
        )
      ),
      exhaustMap(([action, userGuid, packageGuid, productType]) =>
        this.endorsementsService.eraseEndorsement(action.payload).pipe(
          switchMap(() => {
            const commonEraseEndorsementActions = [
              // not techinially calling to signEndorsement is this needed
              EndorsementsActions.SignEndorsementCallCompleted(action),
              EndorsementsActions.SetProcessingEndorsement({
                payload: { isProcessing: false },
              }),
              EndorsementsActions.RollbackEndorsement({
                payload: {
                  endorsementLocationId: action.payload.endorsementLocationId,
                },
              }),
              ...this.getHideLoadingSpinner(productType, true),
            ];

            if (action.payload.endorsementType === EndorsementType.TEXTBOX) {
              return [
                ...commonEraseEndorsementActions,
                EndorsementsActions.ClearUniqueEndorsementImage({
                  payload: {
                    documentEndorsementLocationId: action.payload.endorsementLocationId,
                  },
                }),
              ];
            }

            return [...commonEraseEndorsementActions];
          }),
          catchError((error) => {
            const err = error;
            err.message =
              'Endorsement Attempt Request Failed' +
              ' user:' +
              userGuid +
              ' package:' +
              packageGuid;
            this.applicationInsightsService.logException(err, SeverityLevel.Error);

            // Prevent multiple of the same toast from being displayed
            if (this.notificationId) {
              this.store.dispatch(
                NotificationsActions.ClearNotification({
                  payload: { notificationId: this.notificationId },
                })
              );
            }

            this.notificationId = uuid();
            const actions = [
              ModalsActions.HideLoadingSpinner(),
              ModalsActions.ClearModalComponent(),
              EndorsementsActions.SetProcessingEndorsement({
                payload: { isProcessing: false },
              }),
            ];

            if (error.status === HttpStatusCode.Locked) {
              return [
                ...actions,
                NotificationsActions.AddSnackBarNotification({
                  payload: {
                    component: error.error instanceof AvDisruptionActionBlockingResponse ?
                      AvDisruptionActionBlockingSnackbarComponent : ActionBlockingSnackbarComponent,
                    title: 'Endorsement not erased',
                    body: error.error.text,
                    data: error.error,
                    panelClass: 'mdc-snackbar-error',
                    duration: 12000,
                  },
                }),
              ];
            } else {
              return [
                ...actions,
                NotificationsActions.AddNotification({
                  payload: {
                    notificationType: NotificationType.Info,
                    id: this.notificationId,
                    text: 'Endorsement failed to erase. Please try again.',
                    exceptionType: ExceptionType.DisplayMessage,
                  },
                }),
              ];
            }
          })
        )
      )
    )
  );

  endorsementImageUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.EndorsementImageUpdated),
      switchMap((action) =>
        this.endorsementsService
          .getUpdatedEndorsementImage(action.payload.endorsementId, action.payload.packageUserGuid)
          .pipe(
            switchMap((p: any) => [
              EndorsementsActions.UpdateUniqueEndorsementImage({
                payload: {
                  documentEndorsementLocationId: p.endorsementId,
                  endorsementImageString: p.endorsementImage,
                },
              }),
            ]),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Updated Endorsement Image Request Failed',
                    exceptionType: ExceptionType.ReloadRetry,
                  },
                })
              )
            )
          )
      )
    )
  );

  applySystemFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.ApplySystemFields),
      concatLatestFrom(() => [
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUser)),
        this.store.pipe(select(EndorsementsSelectors.systemFieldApplicationFailures)),
        this.store.pipe(
          select(EndorsementsSelectors.secondsSinceLastSystemFieldApplicationAttempt)
        ),
      ]),
      filter(
        () =>
          this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.NeaSystemFields) ===
          false
      ),
      filter(
        ([_, activePackageUser, __, ___]) =>
          activePackageUser?.userRoleCode.toLowerCase() ===
          PackageUserRole.SIGNINGAGENT.toLowerCase()
      ),
      filter(
        ([_, __, failures, secondsSinceLastAttempt]) =>
          !secondsSinceLastAttempt ||
          secondsSinceLastAttempt > failures * SYSTEM_FIELD_ATTEMPTS_BACKOFF
      ),
      exhaustMap(([_, activePackageUser, failures]) => {
        if (failures >= MAX_SYSTEM_FIELD_ATTEMPTS) {
          return of(
            EndorsementsActions.SetSystemFieldStatus({
              payload: { areApplied: false, failedToApply: true },
            })
          );
        }

        return this.endorsementsService
          .applySystemEndorsements(activePackageUser.packageUserGuid)
          .pipe(
            switchMap(() => of(EndorsementsActions.FetchSystemFieldStatus())),
            catchError((err) => [
              EndorsementsActions.FailedToApplySystemFields(),
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to apply System Endorsements',
                  exceptionType: ExceptionType.CannotProceed,
                },
              }),
            ])
          );
      })
    )
  );

  getHideLoadingSpinner(productType: ProductType, shouldApplySignatureModalShowing: boolean) {
    if (productType === ProductType.RemoteSigning) {
      if (shouldApplySignatureModalShowing) {
        return [ModalsActions.ClearModalComponent()];
      } else {
        return [];
      }
    } else {
      return [ModalsActions.HideLoadingSpinner()];
    }
  }

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  //TODO: find a better name or remove the action from  EndorsementAttemptAccepted
  broadcastRollbackEndorsementAttempted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EndorsementsActions.BroadcastRollbackEndorsementAttempt),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(EndorsementsSelectors.isSignableOnDevice(action.payload.endorsementLocationId))
            )
          )
        )
      ),
      switchMap(([action, isSignableOnDevice]) => {
        if (isSignableOnDevice) {
          return [];
        }
        return [EndorsementsActions.RollbackEndorsementAttempt(action)];
      })
    );
  });

  //TODO: find a better name or remove the action from  EndorrsementAttemptAccepted
  broadcastRollbackendorsement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EndorsementsActions.BroadcastRollbackEndorsement),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(EndorsementsSelectors.isSignableOnDevice(action.payload.endorsementLocationId))
            )
          )
        )
      ),
      switchMap(([action, isSignableOnDevice]) => {
        if (isSignableOnDevice) {
          return [];
        }
        return [EndorsementsActions.RollbackEndorsement(action)];
      })
    );
  });

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  broadcastSetEndorsementAttempted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EndorsementsActions.BroadcastSetEndorsementAttempted),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(EndorsementsSelectors.isSignableOnDevice(action.payload.endorsementLocationId))
            )
          )
        )
      ),
      switchMap(([action, isSignableOnDevice]) => {
        if (isSignableOnDevice) {
          return [];
        }

        return [EndorsementsActions.SetEndorsementAttempted(action)];
      })
    );
  });

  showAttemptingSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        // TODO: User Story 50434: Remove Old Endorsement Application Logic
        EndorsementsActions.EndorsementApplicationStarted
        // if disabling the awaiting modal works we can delete all of this
        // EndorsementsActions.EndorsementProcessingStartedEvent
      ),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(EndorsementsSelectors.isSignableOnDevice(action.payload.endorsementLocationId))
            )
          )
        )
      ),
      switchMap(([_, isSignableOnDevice]) => {
        if (isSignableOnDevice) {
          return [];
        }
        return [
          ModalsActions.SetStandaloneModalComponent({
            payload: {
              component: AwaitingParticipantsModalComponent,
            },
          }),
        ];
      })
    )
  );

  closeAttemptingSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        // TODO: User Story 50434: Remove Old Endorsement Application Logic
        EndorsementsActions.EndorsementApplicationFinished
        // if disabling the modal works we can delete all of this
        // EndorsementsActions.EndorsementProcessingFinishedEvent
      ),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(
              select(EndorsementsSelectors.isSignableOnDevice(action.payload.endorsementLocationId))
            )
          )
        )
      ),
      switchMap(([_, isSignableOnDevice]) => {
        if (isSignableOnDevice) {
          return [];
        }
        return [ModalsActions.ClearModalComponent()];
      })
    )
  );

  // TODO: User Story 50434: Remove Old Endorsement Application Logic
  sendEndorsementApplicationCancelled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.SendEndorsementApplicationCancelled),
      switchMap((action) =>
        this.endorsementsService
          .sendEndorsementApplicationCancelled({
            packageUserGuid: action.payload.packageUserGuid,
            endorsementLocationId: action.payload.endorsementLocationId,
          })
          .pipe(
            switchMap((p) => []),
            catchError((err) =>
              of(
                NotificationsActions.AddNotification({
                  payload: {
                    exception: err,
                    notificationType: NotificationType.Error,
                    id: uuid(),
                    text: 'Failed to post Endorsement Application Cancelled',
                  },
                })
              )
            )
          )
      )
    )
  );

  // TODO: USER STORY 61107: NEA System Field Code Clean-up
  fetchSystemFieldStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.FetchSystemFieldStatus),
      concatLatestFrom(() => [
        this.store.pipe(select(PackageUsersSelectors.getActivePackageUser)),
        this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice)),
        this.store.pipe(select(DeviceGroupSelectors.getIsParticipantRejoining)),
        this.store.pipe(select(EndorsementsSelectors.systemFieldApplicationFailures)),
      ]),
      switchMap(([_, activeUser, usersOnDevice, isParticipantRejoining, failureCount]) =>
        this.endorsementsService.getSystemFieldStatus().pipe(
          switchMap((systemFieldStatus) => {
            const actions: TypedAction<any>[] = [
              EndorsementsActions.SetSystemFieldStatus({ payload: systemFieldStatus }),
            ];

            if (
              systemFieldStatus.areApplied &&
              !!activeUser &&
              usersOnDevice?.length > 0 &&
              !isParticipantRejoining
            ) {
              actions.push(DocumentsActions.FetchSigningSessionDocuments({}));
            }

            if (
              systemFieldStatus.areReadyToApply &&
              (!systemFieldStatus.failedToApply || failureCount === 0) &&
              activeUser?.userRoleCode?.toLowerCase() === PackageUserRole.SIGNINGAGENT.toLowerCase()
            ) {
              actions.push(EndorsementsActions.ApplySystemFields());
            }

            return actions;
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed To Fetch Are All System Endorsements Applied',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        )
      )
    )
  );

  fetchSystemFieldsStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.FetchSystemFieldsStatus),
      switchMap(() =>
        this.endorsementsService.getSystemFieldStatus().pipe(
          switchMap((systemFieldsStatus) => [EndorsementsActions.SetSystemFieldStatus({ payload: systemFieldsStatus })]
          ),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed To Fetch Are All System Endorsements Applied',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        )
      )
    )
  );

  updateSignableOnDeviceStatusForDeviceUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EndorsementsActions.UpdateSignableOnDeviceStatusForDeviceUsers),
      withLatestFrom(this.store.pipe(select(DeviceGroupSelectors.getUsersOnDevice))),
      switchMap(([_, usersOnDevice]) => {
        if (usersOnDevice.length > 1) {
          const actions = [];
          usersOnDevice.forEach((packagUser) => {
            actions.push(
              EndorsementsActions.UpdateSignableOnDeviceStatusByPackageUserGuid({
                payload: { packageUserGuid: packagUser.packageUserGuid, enable: true },
              })
            );
          });
          return actions;
        }

        return [];
      })
    )
  );

  initiateCompleteSigningRoomSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SigningRoomActions.InitiateCompleteSigningRoomSession),
      concatLatestFrom(() => of(undefined)),
      exhaustMap(([_]) =>
        this.endorsementsService.auditEndorsements().pipe(
          switchMap(() => {
            return [SigningRoomActions.CompleteSigningRoomSession()];
          }),
          catchError((err) =>
            of(
              NotificationsActions.AddNotification({
                payload: {
                  exception: err,
                  notificationType: NotificationType.Error,
                  id: uuid(),
                  text: 'Failed to recitify missing endorsements during endorsement audit',
                  exceptionType: ExceptionType.CannotProceed,
                },
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootStoreState.State>,
    private readonly endorsementsService: EndorsementsService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly featureManagementService: FeatureManagementService
  ) { }
}
