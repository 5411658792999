import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services/navigator-media-device-wrapper.service';
import { AudioVideoConnectionMonitorActions } from 'src/app/features/audio-video-connection-monitor/store';
import { ModalsActions } from 'src/app/features/modals';
import { PackagesSelectors } from 'src/app/features/packages';
import { Devices, SoundMeter } from 'src/app/features/ron/models';
import { RootStoreState } from 'src/app/store';

import { AudioVideoCheckActions } from '../../store';
import { AudioVideoBlockedErrorModalComponent } from '../audio-video-blocked-error-modal';

@Component({
  selector: 'app-audio-video-check',
  templateUrl: './audio-video-check.component.html',
  styleUrls: ['./audio-video-check.component.scss'],
})
export class AudioVideoCheckComponent implements OnInit, OnDestroy {
  public availableAudioVideoDevices: Devices;
  public isAudioInputDeviceDetected: boolean;
  public isVideoInputDeviceDetected: boolean;
  public testInProgress: boolean;

  @Output() videoCheckComplete = new EventEmitter<void>();
  @ViewChild('video') video;

  submitted = false;
  avCheckForm: UntypedFormGroup;
  deviceForm: UntypedFormGroup;
  devicesAreLoaded = false;
  audioContext: any;
  audioStream: any;
  meterRefreshInterval: Subscription;
  meterValue = new BehaviorSubject<number>(0);
  selectedCamera: MediaDeviceInfo;
  selectedMicrophone: MediaDeviceInfo;
  soundMeter: any;
  packageGuid$: Observable<string>;

  public stream: MediaStream;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly navigatorMediaDeviceWrapperService: NavigatorMediaDeviceWrapperService
  ) {}

  ngOnDestroy(): void {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();

    // Destroy the existing stream to prevent conflicts with ID verification
    this.stream.getTracks()
      .filter(t => t.readyState === 'live')
      .forEach(t => {
        t.stop();
        this.stream.removeTrack(t);
      });
  }

  ngOnInit() {
    this.store.dispatch(
      AudioVideoConnectionMonitorActions.SetIsOnAudioVideoPage({
        payload: {
          isOnAudioVideoConfigurationPage: true,
        },
      })
    );
    this.store.dispatch(AudioVideoCheckActions.AudioVideoCheckStarted());
    this.store.dispatch(ModalsActions.ShowLoadingSpinner());
    this.packageGuid$ = this.store.select(
      PackagesSelectors.getActivePackageGuid
    );

    this.isVideoInputDeviceDetected = true;
    this.isAudioInputDeviceDetected = true;
    this.testInProgress = false;
    this.availableAudioVideoDevices = new Devices();
    this.initForms();
    this.populateAudioVideoDevices();
  }

  initForms() {
    this.avCheckForm = new UntypedFormGroup({
      canSeeVideo: new UntypedFormControl(null, [Validators.requiredTrue]),
      canHearAudio: new UntypedFormControl(null, [Validators.requiredTrue]),
      isStreamCreated: new UntypedFormControl(null, [Validators.requiredTrue]),
    });

    this.deviceForm = new UntypedFormGroup({
      audio: new UntypedFormControl(null),
      video: new UntypedFormControl(null),
    });
  }

  populateAudioVideoDevices() {
    const mediaConstraints = {
      audio: true,
      video: true,
    };

    this.navigatorMediaDeviceWrapperService
      .getUserMedia(mediaConstraints)
      .pipe(
        tap((results) => {
          this.getAllDevices();
        }),
        catchError((res) => {
          this.store.dispatch(ModalsActions.HideLoadingSpinner());
          if (res) {
            this.store.dispatch(
              ModalsActions.SetStandaloneModalComponent({
                payload: {
                  component: AudioVideoBlockedErrorModalComponent,
                  componentData: {},
                },
              })
            );
          }
          return of(Error(res));
        }),
        take(1)
      )
      .subscribe();
  }

  getAllDevices(): void {
    this.navigatorMediaDeviceWrapperService
      .enumerateDevices()
      .pipe(
        tap((devices) => this.fetchVideoAudioDevices(devices)),
        takeUntil(this.destroySubject)
      )
      .subscribe();
  }

  fetchVideoAudioDevices(devices: MediaDeviceInfo[]): void {
    const selectAudioSources: MediaDeviceInfo[] = [];
    const selectVideoSources: MediaDeviceInfo[] = [];

    const audioSources = devices.filter((device) => {
      return device.kind === 'audioinput';
    });

    audioSources.forEach((deviceInfo) => {
      selectAudioSources.push(deviceInfo);
    });

    this.availableAudioVideoDevices.audio = selectAudioSources;

    if (
      this.availableAudioVideoDevices?.audio !== null &&
      this.availableAudioVideoDevices?.audio.length > 0
    ) {
      const defaultAudioDevice = this.availableAudioVideoDevices.audio[0];
      this.selectedMicrophone = defaultAudioDevice;
      this.updateSelectedAudio();
    }

    const videoSources = devices.filter((device) => {
      return device.kind === 'videoinput';
    });

    videoSources.forEach((deviceInfo) => {
      selectVideoSources.push(deviceInfo);
    });

    this.availableAudioVideoDevices.video = selectVideoSources;

    if (
      this.availableAudioVideoDevices?.video !== null &&
      this.availableAudioVideoDevices?.video.length > 0
    ) {
      const defaultVideoDevice = this.availableAudioVideoDevices.video[0];
      this.selectedCamera = defaultVideoDevice;
      this.updateSelectedVideo();
    }
    this.selectDefaultDevices();
  }

  updateSelectedAudio(): void {
    const currentMicrophoneDeviceId = this.deviceForm.get('audio')?.value;

    if (currentMicrophoneDeviceId) {
      this.selectedMicrophone = this.availableAudioVideoDevices.audio.find(
        (d) => d.deviceId === currentMicrophoneDeviceId
      );
    }

    this.setAudioStream();
  }

  setAudioStream(): void {
    if (this.meterRefreshInterval) {
      this.stopTrackingAudio();
    }

    this.setIntervalForAudioUpdates();
  }

  updateSelectedVideo(): void {
    this.isStreamCreated = false;

    const selectedCameraDeviceId = this.deviceForm.get('video')?.value;
    if (selectedCameraDeviceId) {
      this.selectedCamera = this.availableAudioVideoDevices.video.find(
        (d) => d.deviceId === selectedCameraDeviceId
      );
    }

    const mediaConstraints = {
      video: { deviceId: this.selectedCamera.deviceId },
    };
    this.navigatorMediaDeviceWrapperService
      .getUserMedia(mediaConstraints)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(
        (data) => {
          this.setVideoStream(data);
        },
        (error) => {
          this.handleVideoRetrievalFailure();
        }
      );
  }

  setVideoStream(stream: MediaStream): void {
    this.setVideoControls(stream);
    this.stream = stream;
    this.isStreamCreated = true;
  }

  setVideoControls(stream: MediaStream): void {
    const video: HTMLVideoElement = this.video?.nativeElement;
    if (video) {
      video.muted = true;
      video.playsInline = true;
      video.controls = false;
      video.autoplay = true;
      video.srcObject = stream;
      video.width = 180;
    }
  }

  handleVideoRetrievalFailure(): void {
    alert('Uh Oh, failed to fetch video');
  }

  stopTrackingAudio() {
    this.meterRefreshInterval.unsubscribe();
    this.audioStream.getTracks().forEach((track) => track.stop());
    this.soundMeter.stop();
    this.meterValue.next(0);
  }

  setIntervalForAudioUpdates() {
    try {
      const isAudioContextStandardsCompliant =
        window.AudioContext !== undefined || window.AudioContext !== null;
      if (isAudioContextStandardsCompliant) {
        // Sets the standards compliant version of AudioContext
        this.audioContext = new AudioContext();
      } else {
        // Sets the old webkit version of AudioContext and repoints createScriptProcessor to the old 'createJavaScriptNode' name
        this.audioContext = (window as any).webkitAudioContext;
        if (
          !this.audioContext.prototype.hasOwnProperty('createScriptProcessor')
        ) {
          this.audioContext.prototype.createScriptProcessor = this.audioContext.prototype.createJavaScriptNode;
        }
      }
    } catch (e) {
      alert('Web Audio API not supported.');
    }

    const mediaConstraints = {
      audio: { deviceId: this.selectedMicrophone.deviceId },
    };

    this.navigatorMediaDeviceWrapperService
      .getUserMedia(mediaConstraints)
      .pipe(
        tap((data) => this.handleAudioFetchSuccess(data)),
        takeUntil(this.destroySubject),
        catchError((error) => {
          throw Error(error);
        })
      )
      .subscribe();
  }

  handleAudioFetchSuccess(stream) {
    this.audioStream = stream;
    this.soundMeter = new SoundMeter(this.audioContext);
    this.soundMeter.connectToSource(stream, (e) => {
      if (e) {
        alert(e);
      }
    });

    this.meterRefreshInterval = interval(200)
      .pipe(takeUntil(this.destroySubject))
      .subscribe((n) => {
        this.meterValue.next(this.soundMeter.instant.toFixed(2));
      });
  }

  selectDefaultDevices() {
    this.deviceForm.patchValue({
      audio: this.availableAudioVideoDevices.audio[0].deviceId,
      video: this.availableAudioVideoDevices.video[0].deviceId,
    });
    this.devicesAreLoaded = true;
    this.store.dispatch(ModalsActions.HideLoadingSpinner());
  }

  toggleFormControl(controlName: 'canSeeVideo' | 'canHearAudio') {
    return (event) => {
      if (event.type === 'keypress' && event.key !== ' ') {
        return;
      }

      event.preventDefault();
      const control = this.avCheckForm.get(controlName);

      if (control?.value === true) {
        control.setValue(false);
      } else {
        control.setValue(true);
      }
    }
  }

  saveSettings(): void {
    this.submitted = true;
    this.store.dispatch(
      AudioVideoConnectionMonitorActions.SetSelectedAudioVideoDevices({
        payload: {
          selectedAudioDevice: this.selectedMicrophone,
          selectedVideoDevice: this.selectedCamera,
        },
      })
    );
    this.store.dispatch(
      AudioVideoConnectionMonitorActions.SetIsOnAudioVideoPage({
        payload: {
          isOnAudioVideoConfigurationPage: false,
        },
      })
    );
    if (this.avCheckForm.valid) {
      this.store.dispatch(AudioVideoCheckActions.SaveAuditSessionCapable());
      this.videoCheckComplete.emit();
    }
  }

  get isStreamCreated(): boolean {
    return this.avCheckForm.get('isStreamCreated').value;
  }

  set isStreamCreated(value: boolean) {
    this.avCheckForm.get('isStreamCreated').setValue(value);
  }
}
