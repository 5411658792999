import { Component, inject, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { AudioVideoConnectionMonitorService } from 'src/app/features/audio-video-connection-monitor/services/audio-video-connection-monitor.service';
import { AudioVideoCheckService } from 'src/app/features/av-check';
import { MicCheckService } from 'src/app/features/av-check/services/mic-check.service';
import { SharedComponentService } from 'src/app/features/av-check/services/shared-component.service';
import { SpeakerCheckService } from 'src/app/features/av-check/services/speaker-check.service';

import {
  SelectMicrophone,
  SelectSpeaker,
  SelectVideo,
} from 'src/app/features/av-check/store/actions/audio-video-check.actions';

import { ModalsActions } from 'src/app/features/modals';

@Component({
  selector: 'app-audio-video-modal-v2',
  templateUrl: './audio-video-modal-v2.component.html',
  styleUrls: ['./audio-video-modal-v2.component.scss'],
})
export class AudioVideoModalV2Component implements OnDestroy {
  static identifier = 'AudioVideoModalV2Component';

  private readonly audioVideoCheckService = inject(AudioVideoCheckService);
  private readonly audioVideoConnectionMonitorService = inject(AudioVideoConnectionMonitorService);
  private readonly micCheckService = inject(MicCheckService);
  private readonly speakerCheckService = inject(SpeakerCheckService);
  private readonly store = inject(Store);

  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  private readonly preSelectedAudioInput: MediaDeviceInfo = this.micCheckService.currentMicDevice();

  private readonly preSelectedAudioOutput: MediaDeviceInfo =
    this.speakerCheckService.selectAudioOutputDevice();

  private readonly preSelectedVideo: MediaDeviceInfo =
    this.audioVideoCheckService.selectVideoDevice();

  public sharedComponentService = inject(SharedComponentService);

  ngOnDestroy(): void {
    this.destroySubject.next(undefined);
    this.destroySubject.complete();
  }

  async cancelAudioVideoSelections() {
    this.store.dispatch(SelectSpeaker({ speaker: this.preSelectedAudioOutput }));
    this.store.dispatch(SelectMicrophone({ microphone: this.preSelectedAudioInput }));
    this.store.dispatch(SelectVideo({ video: this.preSelectedVideo }));

    this.store.dispatch(ModalsActions.ClearModalComponent());

    // determine if we have available audio and video devices
    let devices = undefined;

    try {
      devices = await navigator.mediaDevices.enumerateDevices();
    } catch (error) {
      devices = [];
    }

    if (this.audioVideoConnectionMonitorService.shouldDeviceChangeDispatchModal(devices)) {
      this.audioVideoConnectionMonitorService.dispatchModal();
    }
  }

  continue() {
    this.store.dispatch(ModalsActions.ClearModalComponent());
  }
}
