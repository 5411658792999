import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignalRActions } from 'src/app/features/signal-r';
import { RootStoreState } from 'src/app/store';
import { SharedModule } from 'src/app/features/shared';
import { PackagesActions } from 'src/app/features/packages';

@Component({
  selector: 'app-opt-out',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './opt-out.component.html',
  styleUrl: './opt-out.component.scss',
})
export class OptOutComponent implements OnInit {
  constructor(private readonly store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    this.store.dispatch(PackagesActions.SetCancelPackage());
    this.store.dispatch(SignalRActions.StopSignalRConnection());
  }
}
