import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DuplicateSessionDetectorService } from 'src/app/features/duplicate-session-detector';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { SideBarContent } from '../components/side-bar';

@Component({
  selector: 'app-pre-session-layout',
  templateUrl: './pre-session-layout.component.html',
  styleUrls: ['./pre-session-layout.component.scss'],
})
export class PreSessionLayoutComponent implements OnInit, OnDestroy {
  private readonly featureManagementService = inject(FeatureManagementService);
  public readonly isNewAVCheckPage = this.featureManagementService.getIsFeatureEnabledWithCaching(
    Feature.AVCheckPageV2
  );

  public useAudioVideoConnectionMonitor = false;
  public sideBarDynamicContent: SideBarContent;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dupDetectorService: DuplicateSessionDetectorService,
  ) {}

  ngOnInit() {
    this.useAudioVideoConnectionMonitor = this.shouldUseAudioVideoConnectionMonitor();

    if (this.shouldUseDuplicateSessionDetector()) {
      this.dupDetectorService.enable();
    } else {
      this.dupDetectorService.disable();
    }

    this.sideBarDynamicContent = this.route.snapshot?.data?.sideBarContent;
  }

  ngOnDestroy() {
    this.dupDetectorService.disable();
  }

  shouldUseAudioVideoConnectionMonitor(): boolean {
    return (
      this.route.snapshot?.data && !!this.route.snapshot.data['useAudioVideoConnectionMonitor']
    );
  }

  shouldUseDuplicateSessionDetector(): boolean {
    return this.route.snapshot?.data && !!this.route.snapshot.data['useDuplicateSessionDetector'];
  }
}
