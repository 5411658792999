import { inject, Injectable, Signal } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { AudioVideoConnectionMonitorSelectors } from 'src/app/features/audio-video-connection-monitor/store';
import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';
import { ModalsActions, ModalsSelectors } from 'src/app/features/modals';
import { SetStandaloneModalComponent } from 'src/app/features/modals/store/actions/modals.actions';
import { PackageUsersSelectors } from 'src/app/features/package-users';
import { PackagesSelectors } from 'src/app/features/packages';
import { SigningRoomSelectors } from 'src/app/features/signing-room/store';
import { VideoProblemsWithDeviceModalComponent } from 'src/app/features/video/components';
import { VideoActions } from 'src/app/features/video/store';
import { WizardSelectors } from 'src/app/features/wizard';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { RootStoreState } from 'src/app/store';

import {
  INVALID_DEVICE_CHANGE_MODAL_ROUTE_SEGMENTS,
  INVALID_DISPATCH_MODAL_COMPONENT_IDENTIFIERS,
  isValidAudioVideoConnectionMonitorEvent,
} from './audio-video-connection-monitor-utils';

@Injectable({
  providedIn: 'root',
})
export class AudioVideoConnectionMonitorService {
  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  private readonly router = inject(Router);
  private readonly store: Store<RootStoreState.State> = inject(Store);

  private hasLoggedAudioVideoConnected = false;

  private readonly getModalComponent: Signal<unknown> = this.store.selectSignal(
    ModalsSelectors.getModalComponent
  );

  private readonly hasActiveWizardUser: Signal<boolean> = this.store.selectSignal(
    WizardSelectors.hasActiveWizardUser
  );

  private readonly packageCompleteOrCancelled: Signal<boolean> = this.store.selectSignal(
    PackagesSelectors.packageCompleteOrCancelled
  );

  private readonly sessionJoined: Signal<boolean> = this.store.selectSignal(
    SigningRoomSelectors.sessionJoined
  );

  private readonly videoInputDevices: Signal<MediaDeviceInfo[]> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectVideoInputDevices
  );

  private readonly audioInputDevices: Signal<MediaDeviceInfo[]> = this.store.selectSignal(
    AudioVideoCheckSelectors.selectAudioInputDevices
  );

  private readonly packageUserGuid = this.store.selectSignal(
    PackageUsersSelectors.getActivePackageUserGuid
  );

  private readonly packageGuid = this.store.selectSignal(PackagesSelectors.getActivePackageGuid);

  public readonly selectedAudioDevice: Signal<MediaDeviceInfo> = this.store.selectSignal(
    AudioVideoConnectionMonitorSelectors.getSelectedAudioDevice
  );

  public readonly selectedVideoDevice: Signal<MediaDeviceInfo> = this.store.selectSignal(
    AudioVideoConnectionMonitorSelectors.getSelectedVideoDevice
  );

  shouldDeviceChangeDispatchModal(devices: MediaDeviceInfo[]): boolean {
    if (
      !isValidAudioVideoConnectionMonitorEvent(
        this.router.url,
        INVALID_DEVICE_CHANGE_MODAL_ROUTE_SEGMENTS
      )
    ) {
      return false;
    }

    const selectedAudioDevice = this.selectedAudioDevice();
    const selectedVideoDevice = this.selectedVideoDevice();

    const audioAvailable = devices.some(
      ({ deviceId, kind, label }) =>
        kind === 'audioinput' &&
        label === selectedAudioDevice?.label &&
        deviceId === selectedAudioDevice.deviceId
    );

    const videoAvailable = devices.some(
      ({ deviceId, kind, label }) =>
        kind === 'videoinput' &&
        label === selectedVideoDevice?.label &&
        deviceId === selectedVideoDevice.deviceId
    );

    if (
      audioAvailable &&
      videoAvailable &&
      this.videoInputDevices().length &&
      this.audioInputDevices().length
    ) {
      if (!this.hasLoggedAudioVideoConnected) {
        this.hasLoggedAudioVideoConnected = true;

        this.logToApplicationInsights(
          'AV_DEVICE_CONNECTED',
          selectedAudioDevice,
          selectedVideoDevice,
          this.packageUserGuid(),
          this.packageGuid()
        );
      }

      this.store.dispatch(ModalsActions.ClearModalComponent());

      return false;
    }

    this.logToApplicationInsights(
      'AV_DEVICE_DISCONNECTED',
      selectedAudioDevice,
      selectedVideoDevice,
      this.packageUserGuid(),
      this.packageGuid()
    );

    return true;
  }

  logToApplicationInsights(
    eventName,
    selectedAudioDevice,
    selectedVideoDevice,
    packageUserGuid,
    packageGuid
  ) {
    this.applicationInsightsService.logEvent(eventName, {
      route: this.router.url,
      audioDevice: selectedAudioDevice?.label,
      videoDevice: selectedVideoDevice?.label,
      packageUserGuid: packageUserGuid,
      packageGuid: packageGuid,
    });
  }

  dispatchModal() {
    if (
      !isValidAudioVideoConnectionMonitorEvent(
        this.getModalComponent(),
        INVALID_DISPATCH_MODAL_COMPONENT_IDENTIFIERS
      )
    ) {
      return;
    }

    if (this.hasActiveWizardUser() && !this.sessionJoined() && !this.packageCompleteOrCancelled()) {
      this.store.dispatch(
        SetStandaloneModalComponent({
          payload: {
            component: VideoProblemsWithDeviceModalComponent,
            componentData: { isPublishingError: false },
          },
        })
      );

      return;
    }

    if (this.sessionJoined()) {
      this.store.dispatch(
        VideoActions.SetVideoConnectionIssue({
          payload: {
            isConnected: false,
            isPublisher: false,
          },
        })
      );

      return;
    }

    this.store.dispatch(
      ModalsActions.SetStandaloneModalComponent({
        payload: {
          component: VideoProblemsWithDeviceModalComponent,
          componentData: { hideCancelButton: true },
        },
      })
    );
  }
}
