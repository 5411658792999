<div class="device-in-use-modal">
  <div class="device-in-use-modal__header">
    <div class="title">Device already in use</div>
  </div>
  <div class="device-in-use-modal__body">
    <p>
       Your camera and microphone are being used by another application. Please exit any application
      that may be using that device.
    </p>
    <p>
       If you have another device, you may try switching to it using the <strong><span class="text-highlight"><a (click)="openDeviceSettings()">A/V settings</a></span></strong> button below.
    </p>
    <p>
       Once you make your changes, you may need to refresh the page. If this continues to be an issue
      you may have to <strong><span class="text-highlight"><a (click)="cancel()">cancel the session</a>.</span></strong>
    </p>
    <p *ngIf="client$ | async as client">
       Need help? Call us:
      <strong>
          <span class="text-highlight">
            <a href="tel:{{ client.supportPhoneNumber | phone }}">{{client.supportPhoneNumber | phone}}</a>.
        </span>
      </strong>
    </p>
    <div class="device-in-use-modal__footer">
      <button class="btn btn--device-settings" (click)="openDeviceSettings()">
        Go to A/V Settings
      </button>
    </div>
  </div>
</div>
