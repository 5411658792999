import { Type } from '@angular/core';
import { createAction, props } from '@ngrx/store';

import { ModalSize } from '../../enums/modal-sizes.enum';

export const SetModalComponent = createAction(
  '[Modals] Set Modal Component',
  props<{
    payload: {
      component: Type<any>;
      componentData?: any;
      modalTitle?: string;
      allowManualClose?: boolean;
      shouldFade?: boolean;
      modalSize?: ModalSize;
      useBackgroundOverlay?: boolean;
    };
  }>()
);

// Modals where external triggers are required to close it.
export const SetStandaloneModalComponent = createAction(
  '[Modals] Set Standalone Modal Component',
  props<{
    payload: {
      component: Type<any>;
      componentData?: any;
      shouldFade?: boolean;
      modalSize?: ModalSize;
      useBackgroundOverlay?: boolean;
    };
  }>()
);

export const CloseDialog = createAction('[Modals] Close Dialog');
export const ClearModalComponent = createAction('[Modals] Clear Modal Component');
export const ClearCancelSigningModalComponent = createAction('[Modals] Clear Cancel Signing Modal Component');

export const ClearModalComponentIfOpen = createAction(
  '[Modals] Clear Modal Component If Open',
  props<{
    payload: {
      component: Type<any>;
    };
  }>()
);

export const ShowLoadingSpinner = createAction('[Modals] Show Loading Spinner');

export const HideLoadingSpinner = createAction('[Modals] Hide Loading Spinner');

export const EnableGlobalSpinner = createAction('[Modals] Enable Global Spinner');

export const DisableGlobalSpinner = createAction('[Modals] Disable Global Spinner');

export const OpenCancelSigningModal = createAction('[Modals] Open Cancel Signing Modal');

export const SigningCancelled = createAction(
  '[Modals] Signing Cancelled',
  props<{ comments: string; categoryTypeCode: string }>()
);

export const OptOut = createAction(
  '[Cancel Signing Modal] Opt Out',
  props<{ comments: string; categoryTypeCode: string }>()
);
