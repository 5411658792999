import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Feature } from 'src/app/features/feature-management/models';
import { FeatureManagementService } from 'src/app/features/feature-management/services';

import { ModalsActions } from 'src/app/features/modals';
import { RootStoreState } from 'src/app/store';

@Component({
  selector: 'app-cancel-signing-modal',
  templateUrl: './cancel-signing-modal.component.html',
  styleUrls: ['./cancel-signing-modal.component.scss'],
})
export class CancelSigningModalComponent implements OnInit {
  static identifier = 'CancelSigningModalComponent';

  @Input() fixedCategoryTypeCode: string;

  optOutForm: FormGroup;

  protected isOptOutRefactorEnabled: boolean;

  constructor(
    private readonly store: Store<RootStoreState.State>,
    private readonly featureManagementService: FeatureManagementService
  ) {}

  get comments() {
    return this.optOutForm.get('comments');
  }

  ngOnInit(): void {
    this.isOptOutRefactorEnabled = this.featureManagementService.getIsFeatureEnabledWithCaching(Feature.OptOutRefactor);

    this.optOutForm = new FormGroup({
      comments: new FormControl(
        null,
        this.fixedCategoryTypeCode === 'CommentsOnly' ? Validators.required : null
      ),
    });

  }

  getOptOutPackagePayload() {
    return this.fixedCategoryTypeCode === 'CommentsOnly'
      ? {
          ...this.optOutForm.value,
          categoryTypeCode: this.fixedCategoryTypeCode,
        }
      : {
          comments: null,
          categoryTypeCode: this.fixedCategoryTypeCode,
        };
  }

  handleBackButton() {
    this.store.dispatch(ModalsActions.ClearCancelSigningModalComponent())
  }

  handleOptOut() {
    this.optOutForm.markAllAsTouched();

    if (!this.optOutForm.invalid) {
      this.store.dispatch(ModalsActions.CloseDialog());

      if (this.isOptOutRefactorEnabled) {
        this.store.dispatch(ModalsActions.OptOut(this.getOptOutPackagePayload()));
      } else {
        this.store.dispatch(ModalsActions.SigningCancelled(this.getOptOutPackagePayload()));
      }

    }
  }
}
